import img1 from '../assets/1.jpg'
import img2 from '../assets/2.jpg'
import img3 from '../assets/3.jpg'
import img4 from '../assets/4.jpg'
import img5 from '../assets/5.jpg'
import img6 from '../assets/6.jpg'
const data=[
    {
        img : img1,
        content : 'A.I.-Artificial Intelligence'
    }
    ,
    {
        img : img2,
        content : 'python'
    }
    ,
    {
        img : img3,
        content : 'Cyber Security'
    }
    ,
    {
        img : img4,
        content : 'IoT:Internet of Things'
    }
    ,
    {
        img : img5,
        content : 'Data Analysis'
    }
    ,
    {
        img : img6,
        content : 'Machine Learning'
    }
    
];

export default data;